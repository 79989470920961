import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Delivery = () => {
  const lang = "pl";

  return (
    <Layout
      seoTitle="Dostawa"
      lang={lang}
      translationEN="/en/our-services/delivery/"
      translationDE="/de/angebot/lieferung/"
    >
      <SmallBanner
        title="Dostawa"
        aditionalBreadcrump="Oferta"
        aditionalBreadcrumpLink="/oferta/"
      />
      <section className="text-section delivery-info  text-section--first">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main">
                Indywidualne
                <br />
                plany dostaw
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img
                className="delivery-info__left-image"
                src={require("../../assets/img/delivery1.jpg")}
                alt="Dostawa"
              />
            </div>
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="text-section__content">
                <p className="mb-30">
                  PalettenWerk gwarantuje Klientom szybkie i terminowe dostawy
                  palet na terenie całej Europy. Realizacja zamówień na
                  standardowe palety w Polsce odbywa się w ciągu 24 godzin, a
                  dostawy do pozostałych krajów realizowane są do 3 dni
                  roboczych od momentu złożenia zamówienia.
                </p>
                <p className="mb-60">
                  Organizacja procesów produkcji w PalettenWerk pozwala na pełne
                  dostosowanie planów produkcyjnych do cykli i częstości
                  zamówień Klientów. Dzięki temu możliwa jest realizacja
                  indywidualnych planów dostaw w 100% odpowiadających potrzebom
                  Klienta.
                </p>
                <img
                  src={require("../../assets/img/timeline2.jpg")}
                  alt="Dostawa"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="offer-delivery offer-delivery--background-white">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5 col-xl-5">
              <h2 className="small color--main">
                Sposób załadunku
                <br />i bezpieczeństwo ładunku
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 offset-lg-5 offset-xl-4">
              <div className="offer-delivery__content">
                <p className="mb-20">
                  W transporcie wykorzystywane są samochody z naczepami typu
                  standard, mega oraz zestaw, w których jednorazowo może być
                  przewożonych odpowiednio 756, 812, 940 europalet EPAL. Sposób
                  załadunku palet zależy od preferencji Klienta.
                </p>
                <p className="mb-60">
                  Samochody wyposażone są w system GPS GSM, który pozwala na
                  monitoring pojazdów. Każdy przewożony ładunek jest objęty
                  ubezpieczeniem od odpowiedzialności cywilnej przewoźnika
                  drogowego w ruchu krajowym i międzynarodowym.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="delivery-map">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main">
                Dostawy palet
                <br />
                na terenie Europy
              </h2>
            </div>
            <img
              className="delivery-map__image"
              src={require("../../assets/img/delivery-map.png")}
              alt="Mapa Dostaw"
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Delivery;
